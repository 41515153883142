import { Link } from "react-router-dom";
import React from "react";
import { FetchLinkProp, ManipulateUrl } from "../utils/common";

const Dropdown = ({ submenus }) => {
  
  return (
    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
      {submenus.map((submenu, index) => {
        let attribute = submenu['attributes']?.attributes;
        const linkProp=FetchLinkProp(attribute);

        return(
          <li key={index}>
              <Link className={`dropdown-item ${linkProp.classes}`} to={ManipulateUrl(submenu?.url)} target={linkProp?.target} rel={linkProp?.rel} reloadDocument >
                {submenu.title}
              </Link>
          </li>
        )
      })}
    </ul>
  );
};

export default Dropdown;
