import React from 'react'
import { Link } from "react-router-dom";
import { FetchLinkProp,ManipulateUrl } from "../utils/common";
import Dropdown from './Dropdown';

const MenuItem = ({ items }) => {
  const url = typeof items.url === "string" ? ManipulateUrl(items.url) : "";
  
  let attribute = items['attributes']?.attributes;
  const linkProp=FetchLinkProp(attribute);

  return (
    <React.Fragment>
      <li className="nav-item dropdown d-block d-sm-block d-md-block d-lg-none">
          {items.below ? (
              <React.Fragment>
                <Link className={`nav-link dropdown-toggle ${linkProp?.classes}`} target={linkProp?.target} rel={linkProp?.rel} aria-expanded="false"  to={url} id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true">{items.title}</Link>
                <Dropdown submenus={items.below} />
              </React.Fragment>
          ) :  (<Link className={`nav-link ${linkProp.classes}`} target={linkProp?.target} rel={linkProp?.rel}  aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>)}
      </li>
      {/* web view */}
      <li className="nav-item dropdown d-none d-md-none d-lg-block">
          {items.below ? (
              <React.Fragment>
                <Link className={`nav-link dropdown-toggle ${linkProp.classes}`} target={linkProp?.target} rel={linkProp?.rel}  aria-expanded="false"  to={url} style={{pointerEvents:"none"}} id="navbarDropdown" role="button" >{items.title}</Link> 
                <Dropdown submenus={items.below} />
              </React.Fragment>
          ) : (
              <Link className={`nav-link dropdown-toggle ${linkProp.classes}`} target={linkProp?.target} rel={linkProp?.rel} aria-expanded="false" to={url} id="navbarDropdown" role="button" >{items.title}</Link>
          )}
      </li>
    </React.Fragment>
  );
};

export default MenuItem;
