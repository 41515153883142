import axios from "axios";
import apis from "./api";

const APIs= {
    maintainance_mode: `${process.env.REACT_APP_API_URL}/api/v1/maintenance_mode`,
    track_clicks :`${process.env.REACT_APP_API_URL}/api/v1/track-clicks`,
    nav_footer:  `${process.env.REACT_APP_API_URL}/api/v2/menu_items/footer?format=json`,
    nav_login:`${process.env.REACT_APP_API_URL}/api/v2/menu_items/dsci-login?format=json`,
    nav_dsci_top_header:`${process.env.REACT_APP_API_URL}/api/v2/menu_items/dsci-top-header?format=json`,
    nav_dsci_menu:`${process.env.REACT_APP_API_URL}/api/v2/menu_items/dsci-menu?format=json`,
    home_api_set1 :`${process.env.REACT_APP_API_URL}/api/v2/homepage-set1?_format=json`,
    home_api_set2 :`${process.env.REACT_APP_API_URL}/api/v2/homepage-set2?_format=json`,
    home_content_youtube : `${process.env.REACT_APP_API_URL}/api/v1/homecontent_youtube?_format=json`,
    /** certification APIs */
    country_list: `${process.env.REACT_APP_API_URL}/api/v2/country/list`,
    certification_form_status:`${process.env.REACT_APP_API_URL}/api/v2/form-status/certification`,
    session_token:`${process.env.REACT_APP_API_URL}/session/token`,
    registereduser_send_otp: `${process.env.REACT_APP_API_URL}/registereduser/send/otp?_format=json`,
    registereduser_verify_otp: `${process.env.REACT_APP_API_URL}/registereduser/verify/otp?_format=json`,
    certification_referal: `${process.env.REACT_APP_API_URL}/certifications/registration/referal`,
    certification_promocode: `${process.env.REACT_APP_API_URL}/certifications/registration/promocode`,
    certification_registration:(id)=> `${process.env.REACT_APP_API_URL}/certifications/${id}/registration?_format=json`,
    /** webform APIs */
    webform_submission: `${process.env.REACT_APP_API_URL}/api/v1/webformsubmition/?_format=json`,
    webform_target_id_fields:(target_id)=>`${process.env.REACT_APP_API_URL}/webform_rest/${target_id}/fields/?_format=json`,
    webform_config_target_id: (target_id)=>`${process.env.REACT_APP_API_URL}/api/v1/webform-configuration/${target_id}`
};

export function _GETConfig(API_KEY) {
       return {
        method: 'GET',
        headers: { 
            "api-key": API_KEY && API_KEY ? API_KEY: process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
         }
    };
};

export const _GetRequest=async(endpoint)=>{
    try {
        const resp = await fetch(endpoint,_GETConfig())
        const data = await resp.json();
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const _POSTConfig = async(API_KEY)=> {
    try {
        // Fetch CSRF Token directly inside _POSTConfig
        const csrfResponse = await axios.get(apis.session_token);

        if (!csrfResponse || !csrfResponse.data) {
            throw new Error("Failed to fetch CSRF Token");
        }
 
        return {
            "api-key": API_KEY || process.env.REACT_APP_API_KEY,
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfResponse.data, // Assuming `data.data` contains the token
        };
    } catch (error) {
        console.error("POST Config Error:", error);
        return {};
    }
}

export const _PostRequest = async (endpoint, payload) => {
    try {
        // Ensure headers are resolved before making the request
        const headers = await _POSTConfig(); 

        const resp = await axios({
            url: endpoint,
            data: payload,
            method: "post",
            headers: headers,
        });

       
        return resp;
    } catch (error) {
        console.error("Post Request Error:", error);
        return error.response;
    }
};

export function getAuthGETConfig(authToken) {
    return {
        method: 'GET',
        headers: {
            TCRAUTH: authToken,
            'Content-Type': 'application/json',
             
        }
    };
};

export default APIs;

