export const FetchLinkProp=(attribute)=>{
    let classes= '';
    let rel = 'noopener noreferrer';
    let target = '';

    if(attribute?.class && attribute?.class?.length){
        classes=attribute.class.reduce((res, currVal,curInd)=>res+currVal+" ","")
    }
    
    if(attribute?.target && attribute?.target?.length ){
        rel=attribute.rel
        target=attribute.target
    }

    return {classes, rel, target};
}

export const ManipulateUrl=(url, replace_str="backend/")=>{
        return url?.replace(replace_str,""); 
}

export const ConvertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
};