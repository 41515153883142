import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuItem from "./MenuItem";
import APIs,{ _GetRequest } from "../api";

const Header = React.memo(()=> {
  const [topMenuItemsData, setMenuItemsData] = useState();
  const [menuItemsData, setTopMenuItemsData] = useState();
  const [navActive, setnavActive] = useState(false);
  const [userData, setUserData]=useState({});

  useEffect(() => {
    (async()=>{
      try {
        let respTopHeader = await _GetRequest(APIs.nav_dsci_top_header);
        let respHeaderMenu = await _GetRequest(APIs.nav_dsci_menu);
       
        setTopMenuItemsData(respTopHeader.data);
        setMenuItemsData(respHeaderMenu.data);
        setnavActive(true);

        /***login user api fetched  **/
        let loginResp = await _GetRequest(APIs.nav_login)
        const userRespData = await loginResp;
        setUserData(userRespData)

      } catch (error) {
        console.log(error.response);
      }
    })()
  }, []);

  return (
    <React.Fragment>
       {navActive ? (
      <div className="navigation-wrapper">
        {/* top-header */}
        <React.Fragment>
          <div className="top-header d-none-3 ">
            <div className="container-fluid">
              <div className="container container-main">
                <div className="row">
                  <div className="col-md-2 col-6">
                    <Link to="/">
                      <img
                        src={ process.env.REACT_APP_DOMAIN_BASE_URL + "/files/logos/DSCI-White-1.svg" }
                        alt="logo" className="dsci-logo" height={'auto'} width={200}
                      />
                    </Link>
                  </div>
                  <div className="col-md-4 col-4 d-none d-lg-block d-md-block d-xl-block">
                    <nav className="navbar navbar-expand-md navbar-light bg-trans">
                      <div className="container-fluid p-0">
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#navbarSupportedContent2"
                          aria-controls="navbarSupportedContent2"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent2" >
                          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            {menuItemsData?.map((menu, index) => {
                              return <MenuItem items={menu} key={index} />;
                            })}
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div className="col-md-6 col-6">
                    <div className="float-end right-sidee">
                      <ul className="pe-5">
                        <Link to="/search">
                          <img
                            src={ process.env.PUBLIC_URL + "/assets/img/search-1.png" }
                            alt="search" style={{ width: "22px" }} width={"22px"} height={"auto"}
                          />
                        </Link>
                      { userData?.data ? <li className="nav-item dropdown dsci-login-menu">
                          <Link
                            to="/login"
                            className="dropdown-toggle"
                            role="button"
                            id="dropdownMenuLink"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="fas fa-user-alt mr-3"></i>{" "}
                            <span className="mobile-only-2">Login</span>
                          </Link>
                             <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" >
                             {(userData && userData?.data) ? userData?.data?.map((item,index)=>{
                              return(
                                  <li key={index}>  
                                    <a className="dropdown-item" href={item?.url} target="_blank" >
                                      {" "}
                                      <i className="fas fa-user-alt mr-3"></i>
                                      {item?.title}
                                    </a>
                                  </li>
                                  )
                                }):""}
                              </ul>
                      </li> :""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        {/* mobile header */}
        <div className="top-header d-none-2">
          <div className="container-fluid">
            <div className="container-main">
              <div className="row justify-content-center">
                <div className="col-2 col-md-3 ps-md-5">
                  <nav className="navbar navbar-expand-lg navbar-light bg-transs mobile-offcanvas">
                    <div className="container-fluid p-0">
                      <button
                        className="navbar-toggler "
                        type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="offcanvas offcanvas-start "
                        tabIndex="-1"
                        id="offcanvasNavbar2"
                        aria-labelledby="offcanvasNavbar2Label"
                        aria-modal="true"
                        role="dialog"
                      >
                        <div className="offcanvas-header">
                          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            {menuItemsData?.map((menu, index) => {
                              return <MenuItem items={menu} key={index} />;
                            })}
                          </ul>
                          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            {topMenuItemsData?.map((menu, index) => {
                              return <MenuItem items={menu} key={index} />;
                            })}
                          </ul>
                          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown d-block d-sm-block d-md-block">
                              <a
                                href=""
                                className=" nav-link dropdown-toggle"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i></i>
                                <span>Login</span>
                              </a>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" >
                               { (userData && userData?.data) ? userData?.data?.map((item,index)=>{
                                  return (
                                        <li key={index}>
                                          <a className="dropdown-item" href={item?.url} target="_blank" >
                                            {" "}
                                            <i></i>
                                            {item?.title}
                                          </a>
                                        </li>
                                      )
                               }) :""}
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="col-8  col-md-6 text-center">
                  <Link className="navbar-brand" to="/">
                    <img
                      src={ process.env.REACT_APP_DOMAIN_BASE_URL + "/files/logos/DSCI-White-1.svg" }
                      alt="logo" className="dsci-logo" height={'auto'} width={200}
                    />
                  </Link>
                </div>
                <div className="col-2 col-md-3 pe-md-5 pe-3">
                  <Link className="float-end" to="/search">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/search-1.png"}
                      className="me-8" width={"22px"} height={"auto"} style={{ width: "29px", marginTop: "6px" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* web header */}
        {/*Top-Header */}
        <header className="main-nav">
          <div className="container container-main">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <nav className="navbar navbar-expand-lg navbar-light bg-transs">
                  <div className="container-fluid p-0">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                      <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                        {topMenuItemsData?.map((menu, index) => {
                          return <MenuItem items={menu} key={index} />;
                        })}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
       ) : ''}
    </React.Fragment>
  );
});

export default Header;
